import React from 'react';
import type { AppProps } from 'next/app';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';

import '@/styles/globals.css';
import { AnimatePresence, motion } from 'framer-motion';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useRouter } from 'next/router';
import ErrorBoundary from '@/common/components/error-boundary/error-boundary.component';
import { SESSION_STORAGE_KEYS } from '@/common/consts/app-keys.const';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST!,
      // Enable debug mode in development
      loaded: (posthogData) => {
        if (process.env.NODE_ENV === 'development') posthogData.debug();
      }
    });
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      cacheTime: Infinity
    }
  },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      // :tada: only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    }
  })
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  React.useEffect(() => {
    // Track page views and prevRoute

    const handleRouteChange = () => {
      const prevRoutes = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.PREV_ROUTES) || '[]') as string[];

      prevRoutes.unshift(router.asPath);

      sessionStorage.setItem(SESSION_STORAGE_KEYS.PREV_ROUTES, JSON.stringify(prevRoutes));

      posthog?.capture('$pageview');
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <ErrorBoundary>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <AnimatePresence mode="wait">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Component {...pageProps} />
            </motion.div>
          </AnimatePresence>
          <Toaster position="bottom-right" reverseOrder={false} />
        </QueryClientProvider>
      </PostHogProvider>
    </ErrorBoundary>
  );
}
